/* eslint-disable @typescript-eslint/no-unused-vars */
import Link from "next/link"
import dynamic from "next/dynamic"
import {
  NavigationMenu,
  NavigationMenuList,
} from "@/components/ui/navigation-menu"
import ThemeImage from "@/components/ThemeImage"
// UserNavigation を動的インポート
const UserNavigation = dynamic(
  () => import("@/components/navigation/UserNavigation"),
  {
    ssr: false,
  },
)
import ModeToggle from "@/components/navigation/ModeToggle"
import { Separator } from "@/components/ui/separator"
import NavbarDesktop from "@/components/navigation/NavbarDesktop"
import NavbarMobile from "@/components/navigation/NavbarMobile"
import { routeList } from "@/config/routes"

export const Navbar = () => {
  return (
    <header className="container mx-auto sticky mb-2 top-0 z-40 bg-white/95 dark:border-b-slate-700 dark:bg-background/95">
      <NavigationMenu className="py-2">
        <NavigationMenuList className="container mx-auto h-14 px-4 w-screen flex justify-between items-center">
          <Link href="/" className="relative">
            <ThemeImage className="" />
          </Link>

          <NavbarDesktop routeList={routeList} />
          <NavbarMobile routeList={routeList} />

          <div className="flex gap-2 items-center">
            <UserNavigation />
            <ModeToggle />
          </div>
        </NavigationMenuList>
      </NavigationMenu>
      <Separator className="" />
    </header>
  )
}

export default Navbar
